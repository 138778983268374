import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  Suspense,
  useEffect,
  useReducer,
} from "react";
import { EntityRow } from "../../RAFMaster/RMModules/Entity/EntityRow";
import {
  getGridColumnsByModuleAndView,
  getTaskViewsReportsDashboards,
  getUrlByModuleAndView,
  getViewElementId,
  getViewsReportsDashboards,
} from "../../RAFModules/Common/List/IndexHelper";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../RAFModules/Common/Providers/RAFEntityProvider";
import {
  BrowserIsDevice,
  Constants,
  RAFReportType,
  RAFTreeViewMenu,
  rafNoViewName,
} from "../../constants/Common/Constants";
import { RAFEntityType } from "../../constants/Common/RMConstants";
import {
  getModuleAvatarClass,
  getModuleAvatarIcon,
} from "../../helpers/Common/AvatarHelper";
import RAFGridTemplates, {
  getGridColumnTemplatesByModule,
} from "../../helpers/RAFGridTemplates";
import RAFFieldStateProvider from "../Grid/RAFFieldStateProvider";
import RAFEmptyState from "../Navigation/RAFEmptyState";
import RAFAttributeRelatedListProvider from "../Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter } from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { SetGridState } from "../helpers/AppHelper";
import RAFLoadingPanel from "../helpers/RAFLoadingPanel";
import {
  getDisplayNameByModuleName,
  onLookupLinkClicked,
} from "../helpers/RAFMenuHelper";
import {
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../helpers/utils";
import RAFGrid4 from "../Grid/RAFGrid4";
import { RAFEntityName } from "../../constants/Common/EntityConstants";
//const RAFGrid4 = React.lazy(() => import('../Grid/RAFGrid4'));

interface IProps {
  isActive: boolean;
  onClose?: () => void;
  rowclicked?: (value) => void;
  customFilter?: RAFCustomFilter;
  viewUID?: string;
  moduleName: string;
  mode?: "input" | "navigate" | "navigateToNewTab";
  isDynamic?: boolean;
  lookupGridURL?: string;
}

interface IState {
  isActive?: boolean;
  isLoading: boolean;
  viewMenuDataSource: RAFTreeViewMenu[];
  currentView: RAFTreeViewMenu;
  filterBarVisibility?: boolean;
}

function RAFLookupGrid({ ...props }: PropsWithChildren<IProps>) {
  //let dropDownListComponent: DropDownListComponent;

  const lookUpGridId = `grid3_${props.moduleName}_realted_lookUpList`;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: false,
      isLoading: true,
      currentView: null,
      viewMenuDataSource: null,
      filterBarVisibility: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.viewUID, props.moduleName, props.customFilter, props.isActive]);

  const refresh = async () => {
    if (props.isActive) {
      SetGridState(lookUpGridId, null, null);
      setState({ isLoading: true, isActive: true });
      if (isNotNullAndUndefined(props.viewUID)) {
        let allViews;
        if (props.moduleName === RAFEntityName.Task) {
          allViews = await getTaskViewsReportsDashboards(
            props.moduleName,
            true,
            true
          );
        } else {
          allViews = await getViewsReportsDashboards(
            props.moduleName,
            true,
            true
          );
        }

        const viewMenuDataSource = isNotEmptyArray(allViews)
          ? allViews.filter((x) => x.Category === RAFReportType.Table)
          : [];

        const currentView = isNotNullAndUndefined(viewMenuDataSource)
          ? viewMenuDataSource.find((x) => x.UID === props.viewUID)
          : null;

        setState({
          currentView,
          isActive: true,
          isLoading: false,
        });
      } else {
        let allViews;
        if (props.moduleName === RAFEntityName.Task) {
          allViews = await getTaskViewsReportsDashboards(
            props.moduleName,
            true,
            true
          );
        } else {
          allViews = await getViewsReportsDashboards(
            props.moduleName,
            true,
            true
          );
        }
        const viewMenuDataSource = isNotEmptyArray(allViews)
          ? allViews.filter((x) => x.Category === RAFReportType.Table)
          : [];

        const currentView = isNotNullAndUndefined(viewMenuDataSource)
          ? viewMenuDataSource[0]
          : null;
        setState({
          isActive: true,
          isLoading: false,
          currentView,
          viewMenuDataSource,
        });
      }
    } else {
      setState({ isActive: false });
    }
  };

  function onClickCloseDialog() {
    SetGridState(lookUpGridId, null, null);
    if (props.onClose) {
      props.onClose();
    }
  }

  function rowClick(itemRow) {
    if (isNotNullAndUndefined(itemRow)) {
      if (props.mode === "input") {
        SetGridState(lookUpGridId, null, null);
        if (isNotNullAndUndefined(itemRow)) {
          setState({ isActive: false });
          if (props.rowclicked) {
            props.rowclicked(itemRow);
          }
        }
      } else {
        onLookupLinkClicked(props.moduleName, itemRow.UID, props.isDynamic);
      }
    }
  }

  const onViewItemChange = (e) => {
    if (isNotNullAndUndefined(e)) {
      setState({ isLoading: true });
      let { viewMenuDataSource } = state;
      let currentView = isNotNullAndUndefined(viewMenuDataSource)
        ? viewMenuDataSource.find(
            (x) =>
              (isNotNullAndUndefined(x.UID) && x.UID === e.itemValue) ||
              x.Name === e.itemValue
          )
        : null;
      setTimeout(() => {
        setState({ isLoading: false, currentView });
      }, 100);
    }
  };

  const getDropdownInput = () => {
    const { viewMenuDataSource, currentView } = state;
    const datasource: { itemValue: string; DisplayName: string }[] = [];
    if (
      isNotNullAndUndefined(viewMenuDataSource) &&
      viewMenuDataSource.length > 0
    ) {
      viewMenuDataSource.forEach((item, index) => {
        datasource.push({
          DisplayName: item.DisplayName,
          itemValue: isNotNullAndUndefined(item.UID) ? item.UID : item.Name,
        });
      });
    }

    const itemValue = isNotNullAndUndefined(currentView)
      ? isNotNullAndUndefined(currentView.UID)
        ? currentView.UID
        : currentView.Name
      : null;

    return (
      <DropDownListComponent
        cssClass="primary-custom-button border-0"
        change={(e) => onViewItemChange(e.itemData)}
        //ref={e => dropDownListComponent = e}
        fields={{ text: "DisplayName", value: "itemValue" }}
        dataSource={datasource as any}
        value={itemValue ?? null}
        allowFiltering
        width="100%"
        filterType="Contains"
      />
    );
  };

  const getHeaderViewDropdown = () => {
    let iconName = getModuleAvatarIcon(props.moduleName);
    let text = getDisplayNameByModuleName(props.moduleName);
    let avatarClassName = getModuleAvatarClass(props.moduleName);
    return (
      <div className="row align-items-center g-2 flex-nowrap">
        <div className="col-auto">
          <TooltipComponent
            content={text}
            position="BottomCenter"
            opensOn="Hover"
          >
            <div
              className={
                BrowserIsDevice
                  ? "backArrowIcon backArrowIcon-lg avatar-text " +
                    avatarClassName
                  : "backArrowIcon backArrowIcon-lg avatar-text " +
                    avatarClassName
              }
            >
              <i className={"fa fa-" + iconName + " headerIcon"}></i>
            </div>
          </TooltipComponent>
        </div>
        <div className="col col-sm-auto">
          {isNotNullAndUndefined(props.viewUID) ? (
            <div>
              <span
                className="breadcrumb-text active ecllipseFirstLine text-break ms-2"
                style={{ fontSize: "18px" }}
              >
                {text}
              </span>
            </div>
          ) : (
            <div>{getDropdownInput()}</div>
          )}
        </div>
      </div>
    );
  };

  const getAdditionalParams = (entity: EntityRow) => {
    let additionalParams;
    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntityName) &&
      entity.EntityType !== RAFEntityType.UserForm
    ) {
      additionalParams = [
        {
          key: "EntityName",
          value: props.moduleName,
        },
      ];
    }
    return additionalParams;
  };

  const getGridContent = () => {
    const { currentView } = state;
    if (state.isLoading === false) {
      if (
        isNotNullAndUndefined(currentView) &&
        (isNotNullAndUndefined(currentView.UID) ||
          (IsNullOrWhiteSpace(currentView.UID) &&
            currentView.Name !== rafNoViewName)) &&
        isNotNullAndUndefined(currentView.Category)
      ) {
        const { moduleName, lookupGridURL } = props;
        const url = isNotNullAndUndefined(lookupGridURL)
          ? `${Constants.baseAPIUrl}${lookupGridURL}`
          : `${Constants.baseAPIUrl}DataList/List`;

        return (
          <div className="e-card-content h-100">
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFEntityProvider moduleName={moduleName}>
                <RAFEntityContext.Consumer>
                  {({ entity }) => {
                    return (
                      <div
                        className="col-12 p-0 h-100"
                        key={`${getViewElementId(
                          currentView.Name,
                          currentView.UID
                        )}`}
                      >
                        {currentView.Category === RAFReportType.Table && (
                          <div className="px-0 hover-table-div h-100">
                            <Suspense fallback={<div>Loading...</div>}>
                              <RAFFieldStateProvider
                                moduleName={props.moduleName}
                                viewId={currentView.UID}
                              >
                                <RAFGridTemplates>
                                  <RAFGrid4
                                    url={
                                      isNotNullAndUndefined(url)
                                        ? url
                                        : getUrlByModuleAndView(
                                            props.moduleName,
                                            currentView
                                          )
                                    }
                                    gridId={lookUpGridId}
                                    moduleName={props.moduleName}
                                    isRemote
                                    rowClick={rowClick}
                                    showEditColumn={false}
                                    allowEditing={false}
                                    allowSelection={false}
                                    allowFiltering
                                    gridTemplates={getGridColumnTemplatesByModule(
                                      props.moduleName
                                    )}
                                    disableFirstColumnTemplate
                                    emptyStateProps={{
                                      title: "No results found.",
                                      body: "Try adjusting your search or changing your filter to find what you're looking for!",
                                    }}
                                    viewId={currentView.UID}
                                    filterBarVisibile={false}
                                    additionalFilter={props.customFilter}
                                    additionalParams={getAdditionalParams(
                                      entity
                                    )}
                                    {...(isNotNullAndUndefined(props.moduleName)
                                      ? { isDynamic: true }
                                      : {})}
                                    //filterType="QueryBuilder"
                                  >
                                    {getGridColumnsByModuleAndView(
                                      props.moduleName,
                                      currentView
                                    )}
                                  </RAFGrid4>
                                </RAFGridTemplates>
                              </RAFFieldStateProvider>
                            </Suspense>
                          </div>
                        )}
                      </div>
                    );
                  }}
                </RAFEntityContext.Consumer>
              </RAFEntityProvider>
            </RAFAttributeRelatedListProvider>
          </div>
        );
      } else {
        return (
          <div className="pb-sm-3 h-100">
            {isNotNullAndUndefined(currentView) && (
              <RAFEmptyState
                body="No view found. Add a view under this category"
                title="No results found."
              />
            )}
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <RAFLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  };

  if (state.isActive) {
    return (
      <div className="e-dlg-content-outer">
        <div className="e-dlg-header-content">
          <div className="row gx-3 align-items-center">
            <div className="col">{getHeaderViewDropdown()}</div>
            <div className="col-auto">
              <ButtonComponent
                type="button"
                cssClass="primary-custom-button"
                iconCss="fas fa-xmark"
                onClick={() => onClickCloseDialog()}
              />
            </div>
          </div>
        </div>
        <div className="e-dlg-body-content h-100 overflow-hidden mb-3">
          {getGridContent()}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(RAFLookupGrid);
